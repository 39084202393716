import React from "react";
import classNames from "classnames";

const NotesInput = ({
  className,
  inputClassName,
  value,
  multi,
  disabled,
  ...props
}) => {
  return (
    <div className={classNames("flex flex-col", className)}>
      {!multi ? (
        <input
          disabled={disabled}
          type="text"
          className={classNames("input", inputClassName)}
          value={value}
          {...props}
        />
      ) : (
        <textarea
          disabled={disabled}
          className={classNames("input !w-100", inputClassName)}
          value={value}
          {...props}
        />
      )}
    </div>
  );
};

export default NotesInput;

import React, { useState, useRef, useEffect } from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

import {
  getImageWithPlaceholder,
  getOrientation,
  isVisible,
} from "../utils/utils";
import Magnify from "./magnify";

const ProductImages = ({ product, variantImageId }) => {
  const thumbnailListRef = useRef();
  const { title, featuredMedia, media } = product;

  let variantImageIndex = media.findIndex(
    (image) => image.shopifyId === variantImageId
  );

  // put featured image first
  media.sort((a, b) => {
    if (a.shopifyId === featuredMedia.id) {
      return -1;
    }
    return 0;
  });

  let settings = {
    thumbnail: {
      width: 100,
      gap: 10,
    },
  };

  // const hasImages = mediaCount > 0;
  // const hasMultipleImages = true || mediaCount > 1;

  const [currentImg, setCurrentImg] = useState(0);

  const isFirst = currentImg === 0;
  const isLast = currentImg === media.length - 1;

  const handleSelected = (image) => {
    setCurrentImg(image || 0);
  };

  const handlePrevious = () => {
    if (currentImg > 0) {
      let previous = currentImg - 1;
      thumbnailIntoView(getThumbnailElem(previous));
      setCurrentImg(previous);
    }
  };

  const handleNext = () => {
    if (currentImg < media.length - 1) {
      let next = currentImg + 1;
      thumbnailIntoView(getThumbnailElem(next));
      setCurrentImg(next);
    }
  };

  const getThumbnailElem = (index) => {
    let thumbnails = thumbnailListRef.current.querySelectorAll("li");

    return thumbnails[index];
  };

  const thumbnailIntoView = (thumbnail) => {
    let list = thumbnailListRef.current;
    let listRect = list.getBoundingClientRect();
    let thumbnailRect = thumbnail.getBoundingClientRect();

    if (!isVisible(thumbnail, list)) {
      if (getOrientation(list) === "horizontal") {
        if (thumbnailRect.left >= listRect.left) {
          list.scrollLeft =
            thumbnail.offsetLeft + thumbnailRect.width - listRect.width;
        } else {
          list.scrollLeft = thumbnail.offsetLeft;
        }
      } else {
        if (thumbnailRect.bottom >= listRect.bottom) {
          list.scrollTop =
            thumbnail.offsetTop + thumbnailRect.width - listRect.height;
        } else {
          list.scrollTop = thumbnail.offsetTop;
        }
      }
    }
  };

  useEffect(() => {
    if (media.length > 0) {
      handleSelected(variantImageIndex > -1 ? variantImageIndex : 0);
      thumbnailIntoView(
        getThumbnailElem(variantImageIndex > -1 ? variantImageIndex : 0)
      );
    }
  }, [media, variantImageIndex]);

  return (
    <div className="flex flex-col sm:flex-row">
      <div
        className="flex-grow flex sm:order-2 sm:pl-6 mb-4 sm:mb-0"
        style={{ maxHeight: 700 }}
      >
        <button
          className={classNames(
            isFirst ? "bg-gray-400 cursor-not-allowed" : "bg-gray-200",
            "flex items-center focus:outline-none"
          )}
          onClick={handlePrevious}
          disabled={isFirst}
        >
          <FontAwesomeIcon
            icon={faChevronLeft}
            className="text-gray-700 mx-2"
          />
          <span className="sr-only">Previous Image</span>
        </button>
        {media[currentImg] ? (
          <Magnify
            className="flex-grow"
            style={{ backgroundColor: "rgba(209, 213, 219,.2)" }}
            image={media[currentImg].image}
            alt={
              media[currentImg]?.image.altText ||
              `Product Image of ${title} #${currentImg}`
            }
          />
        ) : (
          <div
            style={{
              backgroundColor: "rgba(209, 213, 219,.2)",
              width: "100%",
              height: 500,
            }}
          ></div>
        )}
        <button
          className={classNames(
            isLast ? "bg-gray-400 cursor-not-allowed" : "bg-gray-200",
            "flex items-center focus:outline-none"
          )}
          onClick={handleNext}
          disabled={isLast}
        >
          <FontAwesomeIcon
            icon={faChevronRight}
            className="text-gray-700 mx-2"
          />
          <span className="sr-only">Next Image</span>
        </button>
      </div>
      <div>
        <ul
          ref={thumbnailListRef}
          className="thumbnails"
          style={{
            gap: settings.thumbnail.gap,
          }}
        >
          {media.length > 0 ? (
            media.map(
              ({ image: { altText, thumbnail, placeholder } }, index) => (
                <li key={index}>
                  <button
                    style={{
                      width: settings.thumbnail.width,
                      height: settings.thumbnail.width,
                    }}
                    className={classNames(
                      "border-2 transition-colors duration-100",
                      "focus:outline-none",
                      {
                        "border-studio-burnt-umber": currentImg === index,
                      }
                    )}
                    onClick={(e) => {
                      thumbnailIntoView(e.target.closest("li"));
                      handleSelected(index);
                    }}
                  >
                    <GatsbyImage
                      key={thumbnail}
                      image={getImageWithPlaceholder(thumbnail, placeholder)}
                      alt={
                        altText ||
                        `Product Image Thumbnail of ${title} #${index + 1}`
                      }
                    />
                    <span className="sr-only">
                      View Product Image Thumbnail of {title} #{index + 1}
                    </span>
                  </button>
                </li>
              )
            )
          ) : (
            <li>
              <button
                style={{
                  width: settings.thumbnail.width,
                  height: settings.thumbnail.width,
                }}
                className={classNames(
                  "border-2 transition-colors duration-100",
                  "focus:outline-none"
                  // "border-studio-burnt-umber"
                )}
              >
                <span className="sr-only">No Images</span>
              </button>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default ProductImages;

export const query = graphql`
  fragment ProductImages on ShopifyProduct {
    title
    mediaCount
    featuredMedia {
      ... on ShopifyMediaImage {
        id
      }
    }
    media {
      ... on ShopifyMediaImage {
        id
        shopifyId
        image {
          altText
          # thumbnail: localFile {
          #   childImageSharp {
          #     gatsbyImageData(
          #       width: 100
          #       aspectRatio: 1
          #       placeholder: BLURRED
          #       transformOptions: { cropFocus: CENTER }
          #     )
          #   }
          # }
          placeholder: gatsbyImageData(width: 8)
          thumbnail: gatsbyImageData(width: 100, aspectRatio: 1)
        }
        ...Magnify
      }
    }
  }
`;

import React, { useRef, useState } from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image";
import classNames from "classnames";
import { Helmet } from "react-helmet";

import { getImageWithPlaceholder } from "../utils/utils";
// import { getOriginal } from "../utils/utils";

const Magnify = ({ image, alt, className, ...props }) => {
  const wrapperRef = useRef();

  const [fullscreen, setFullscreen] = useState(false);
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);

  const handleClick = (e) => {
    if (isTouchDevice()) {
      if (fullscreen) {
        setFullscreen(false);
      } else {
        setFullscreen(true);
      }
    }
  };

  const handleMouseEnter = () => {
    let img = getImage(image.large);
    let orig = { width: image.width, height: image.height }; // getOriginal(image.large);
    wrapperRef.current.bounds = wrapperRef.current.getBoundingClientRect();

    wrapperRef.current.ratios = getRatios(
      wrapperRef.current.bounds,
      orig.width < img.width && orig.height < img.height ? orig : img
    );
  };

  const handleMouseMove = (e) => {
    if (wrapperRef.current.ratios && wrapperRef.current.bounds) {
      setX(-(e.nativeEvent.offsetX * wrapperRef.current.ratios.x).toFixed(0));
      setY(-(e.nativeEvent.offsetY * wrapperRef.current.ratios.y).toFixed(0));
    } else {
      handleMouseEnter();
    }
  };

  const getRatios = (bounds, dimensions) => {
    return {
      x: (dimensions.width - bounds.width) / bounds.width,
      y: (dimensions.height - bounds.height) / bounds.height,
    };
  };

  const isTouchDevice = () => {
    return (
      "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    );
  };

  return (
    <div
      ref={wrapperRef}
      tabIndex="0"
      role="button"
      className={classNames("image-preview overflow-hidden", className)}
      onClick={handleClick}
      onKeyDown={(e) => {
        if (e instanceof KeyboardEvent && e.key !== "Enter" && e.key !== " ") {
          return;
        }
        handleClick(e);
      }}
      onMouseEnter={handleMouseEnter}
      onMouseMove={handleMouseMove}
      {...props}
    >
      <Helmet bodyAttributes={{ "data-fullscreen": fullscreen }} />
      <GatsbyImage
        className="image-original"
        image={getImageWithPlaceholder(image.preview, image.placeholder)}
        alt={alt}
        objectFit="contain"
      />
      <div
        className="image-magnify"
        style={{
          width: getImage(image.large).width,
          height: getImage(image.large).height,
          backgroundImage: `url(${getSrc(image.large)})`,
          backgroundRepeat: "no-repeat",
          backgroundPositionX: x,
          backgroundPositionY: y,
        }}
      ></div>
      <div className={classNames("image-fullscreen", { open: fullscreen })}>
        <GatsbyImage
          image={getImageWithPlaceholder(image.large, image.placeholder)}
          alt={alt}
          objectFit="contain"
        />
        <div className="text-center bg-gray-700">
          Pinch to Zoom. Tap to Close.
        </div>
      </div>
    </div>
  );
};

export default Magnify;

export const query = graphql`
  fragment Magnify on ShopifyMediaImage {
    image {
      # preview: localFile {
      #  childImageSharp {
      #     gatsbyImageData(width: 640, placeholder: BLURRED)
      #   }
      # }
      preview: gatsbyImageData(width: 640)
      # large: localFile {
      #   childImageSharp {
      #     original {
      #       width
      #       height
      #    }
      #     gatsbyImageData(width: 1200, placeholder: BLURRED)
      #   }
      # }
      large: gatsbyImageData(width: 1200)
      width
      height
    }
  }
`;

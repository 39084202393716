import React, { useContext } from "react";
import classNames from "classnames";
import { ClientOnly } from "react-client-only";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faSpinner } from "@fortawesome/free-solid-svg-icons";

import { variantInCheckout, variantQtyInCheckout } from "../utils/utils";
import { StoreContext, STOCK_STATES } from "../utils/store-context";
import { useStock } from "../hooks/useStock";
import { useMetafields } from "../hooks/useMetafields";

const AddToCart = ({
  product,
  variantId,
  quantity,
  customAttributes,
  minQty,
  maxQty,
  initStock,
  className,
  disabled,
  ...props
}) => {
  const { IN_STOCK, LIMITED_STOCK, OUT_OF_STOCK } = STOCK_STATES;
  const { checkout, addVariantToCart, loading, didJustAddToCart } =
    useContext(StoreContext);
  const { checking, stock } = useStock(
    product.storefrontId,
    variantId,
    initStock
  );
  const meta = useMetafields(product.metafields);

  const limitUniqueItemAdd =
    meta.isUniqueItem && variantInCheckout(checkout, variantId);

  const atMaxQty = variantQtyInCheckout(checkout, variantId) >= maxQty;

  function addToCart(e) {
    e.preventDefault();
    if (!limitUniqueItemAdd && !atMaxQty) {
      addVariantToCart(variantId, quantity, customAttributes);
    }
  }

  let generalButtonText = "Add to Cart";

  if (limitUniqueItemAdd) {
    generalButtonText = "Already in Cart";
  } else if (atMaxQty) {
    generalButtonText = "Max Qty in Cart";
  }

  return (
    <button
      type="submit"
      onClick={addToCart}
      disabled={
        disabled ||
        stock === OUT_OF_STOCK ||
        !variantId ||
        checking ||
        loading ||
        didJustAddToCart
      }
      className={classNames(
        "btn",
        didJustAddToCart
          ? "bg-green-500"
          : variantId &&
            !limitUniqueItemAdd &&
            !atMaxQty &&
            !disabled &&
            (stock === IN_STOCK ||
              stock === LIMITED_STOCK ||
              loading ||
              checking)
          ? "btn-primary"
          : "btn-gray cursor-not-allowed",
        "no-zoom",
        className
      )}
      {...props}
    >
      <ClientOnly>
        {checking || loading ? (
          <FontAwesomeIcon
            title="Loading"
            icon={faSpinner}
            className="text-xl mr-2"
            style={{ width: "1em" }}
            spin={true}
          />
        ) : didJustAddToCart ? (
          <>
            <FontAwesomeIcon icon={faCheck} className="text-xl" />
            Added to Cart
          </>
        ) : (
          <>{generalButtonText}</>
        )}
      </ClientOnly>
    </button>
  );
};

export default AddToCart;

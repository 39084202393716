import React from "react";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";

const Breadcrumbs = ({ crumbs, className, ...props }) => {
  let crumbParts = [];
  let arrow = (
    <FontAwesomeIcon
      icon={faAngleRight}
      className="mx-2"
      style={{ width: "0.5em" }}
    />
  );

  crumbs.forEach((crumb, index) => {
    let isLastCrumb = crumbs.length === index + 1;

    if (typeof crumb === "object") {
      crumbParts.push(<Link to={crumb.to}>{crumb.label}</Link>);

      if (!isLastCrumb) {
        crumbParts.push(arrow);
      }
    } else {
      crumbParts.push(<span className="text-gray-400">{crumb}</span>);

      if (!isLastCrumb) {
        crumbParts.push(arrow);
      }
    }
  });

  return (
    <div className={classNames("flex", className)} {...props}>
      {crumbParts.map((crumb, index) => (
        <span key={index}>{crumb}</span>
      ))}
    </div>
  );
};

export default Breadcrumbs;

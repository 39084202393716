import React, { useState, useRef } from "react";
import { graphql } from "gatsby";
import _ from "lodash";
import classNames from "classnames";

import {
  formatCurrency,
  formatPriceRange,
  getSelectedRadioOption,
  toKabobCase,
} from "../utils/utils";
import { STOCK_STATES } from "../utils/store-context";
import { useMetafields } from "../hooks/useMetafields";

import AddToCart from "./addToCart";
import QuantityInput from "./quantityInput";
import InStockIndicator from "./inStockIndicator";
import UniqueItem from "./uniqueItem";
import NotesInput from "./notesInput";

const ProductForm = ({ product, onChangeVariantImage }) => {
  const { IN_STOCK, OUT_OF_STOCK } = STOCK_STATES;
  const optionsWrapperRef = useRef();
  const {
    options,
    hasOnlyDefaultVariant,
    variants,
    variants: [initialVariant],
    priceRangeV2,
    priceRangeV2: { minVariantPrice, maxVariantPrice },
    metafields,
  } = product;
  const meta = useMetafields(metafields);

  const getDefaultSelectedOptions = () =>
    options.reduce((defaultOptions, { name }) => {
      defaultOptions.push({ name, value: "" });
      return defaultOptions;
    }, []);

  const [variant, setVariant] = useState(
    hasOnlyDefaultVariant ? initialVariant : null
  );
  const [quantity, setQuantity] = useState(1);
  const [minQty] = useState(1);
  const [maxQty, setMaxQty] = useState(meta.getMaxQty() || 20);
  const [selectedOptions, setSelectedOptions] = useState(
    getDefaultSelectedOptions()
  );

  // Custom Attributes
  const notes_1Label = meta.getNotes1();
  const notes_2Label = meta.getNotes2();
  const notes_3Label = meta.getNotes3();
  const notes_1Required = meta.isNotes1Required;
  const notes_2Required = meta.isNotes2Required;
  const notes_3Required = meta.isNotes3Required;
  const [notes_1, setNotes_1] = useState("");
  const [notes_2, setNotes_2] = useState("");
  const [notes_3, setNotes_3] = useState("");

  const validateCustomAttributes = () => {
    let required = [];

    if (notes_1Required) required.push(notes_1);
    if (notes_2Required) required.push(notes_2);
    if (notes_3Required) required.push(notes_3);

    return required.some((attr) => attr === "");
  };

  const getCustomAttributes = () => {
    let attributes = [];

    if (meta.hasNotes1) attributes.push({ key: notes_1Label, value: notes_1 });
    if (meta.hasNotes2) attributes.push({ key: notes_2Label, value: notes_2 });
    if (meta.hasNotes3) attributes.push({ key: notes_3Label, value: notes_3 });

    return attributes;
  };

  const handleOptionChange = () => {
    const currentSelectedOptions = updateSelectedOptions();

    // Deselect Variant if not all options are selected
    if (currentSelectedOptions.find((option) => option.value === "")) {
      setVariant(null);
      return;
    }

    const selectedVariant = variants.find((variant) =>
      _.isEqual(currentSelectedOptions, variant.selectedOptions)
    );
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const variantMeta = useMetafields(selectedVariant.metafields);

    if (selectedVariant && variantMeta.hasMaxQty) {
      setMaxQty(variantMeta.getMaxQty());
    }

    setVariant({ ...selectedVariant });
    onChangeVariantImage(selectedVariant?.media[0]?.shopifyId);
  };

  const updateSelectedOptions = () => {
    const selectedOptions = Array.from(
      optionsWrapperRef.current.querySelectorAll(".variant-option")
    ).reduce((selectedOptions, option) => {
      selectedOptions.push({
        name: option.getAttribute("name"),
        value: getSelectedRadioOption(option),
      });
      return selectedOptions;
    }, []);
    setSelectedOptions(selectedOptions);
    return selectedOptions;
  };

  const optionsWithValues = selectedOptions.filter((x) => x.value !== "");
  const variantsOfCurrentSelection = variants
    .filter(
      (variant) =>
        _.unionWith(variant.selectedOptions, optionsWithValues, _.isEqual)
          .length === variant.selectedOptions.length
    )
    .map((variant) => variant.selectedOptions);

  const variantOptionExists = (name, value) => {
    let optionsIsAvailable = variantsOfCurrentSelection.some(
      (variantOptions) =>
        variantOptions.find((option) => option.name === name)?.value === value
    );

    return optionsIsAvailable;
  };

  const resetOptions = () => {
    let productFormRadios = document.querySelectorAll(
      '.variant-option input[type="radio"]'
    );

    productFormRadios.forEach((radio) => {
      radio.checked = false;
    });
  };

  const hasVariants = variants.length > 1;

  return (
    <div className="flex flex-col items-start">
      <span className="h2 !mb-1">
        {variant
          ? formatCurrency(
              variant?.price,
              priceRangeV2.minVariantPrice.currencyCode
            )
          : formatPriceRange(
              minVariantPrice.amount,
              maxVariantPrice.amount,
              minVariantPrice.currencyCode
            )}
      </span>
      <InStockIndicator
        product={product}
        variantId={variant?.storefrontId}
        className="mb-3"
      />
      {hasVariants && (
        <div ref={optionsWrapperRef} className="variant-options">
          {options.map(({ shopifyId, name, values }, index) => (
            <div key={index}>
              <span className="block font-bold text-lg mb-1">{name}</span>
              <ul
                id={shopifyId}
                className="variant-option flex flex-wrap gap-3 list-none m-0 mb-3 p-0"
                aria-label="Variants"
                name={name}
              >
                {values.map((value) => {
                  let exists = variantOptionExists(name, value);

                  return (
                    <li key={`${name}-${value}`}>
                      <input
                        id={toKabobCase(`${name} ${value}`)}
                        type="radio"
                        className="sr-only"
                        name={toKabobCase(name)}
                        value={value}
                        onChange={handleOptionChange}
                      />
                      <label
                        htmlFor={toKabobCase(`${name} ${value}`)}
                        className={classNames(
                          "rounded-full px-3 py-1 cursor-pointer",
                          exists ||
                            selectedOptions.find(
                              (option) => option.name === name
                            )?.value === value
                            ? "bg-gray-200 text-black"
                            : "bg-gray-600 text-gray-300"
                        )}
                        value={value}
                        onClick={() => {
                          if (!exists) {
                            resetOptions();
                            handleOptionChange();
                          }
                        }}
                      >
                        {value}
                      </label>
                    </li>
                  );
                })}
              </ul>
              {/* <label className="block font-bold text-lg mb-1" htmlFor={id}>
                {name}
              </label>
              <select
                id={id}
                className="variant-option input input-select mb-3"
                aria-label="Variants"
                name={name}
                onChange={handleOptionChange}
              >
                <option value="">{`Select ${name}`}</option>
                {values.map((value) => (
                  <option
                    value={value}
                    key={`${name}-${value}`}
                    disabled={checkOptionDisabled(name, value)}
                  >
                    {value}
                  </option>
                ))}
              </select> */}
            </div>
          ))}
        </div>
      )}
      {meta.hasNotes1 && (
        <>
          <label className="font-bold text-lg mb-1" htmlFor="item-quantity">
            {notes_1Label}
            {!notes_1Required && (
              <>
                {" "}
                <span className="text-sm text-gray-400">(optional)</span>
              </>
            )}
          </label>
          <NotesInput
            className="mb-3"
            value={notes_1}
            required={notes_1Required}
            onChange={(e) => setNotes_1(e.target.value)}
          />
        </>
      )}
      {meta.hasNotes2 && (
        <>
          <label className="font-bold text-lg mb-1" htmlFor="item-quantity">
            {notes_2Label}
            {!notes_2Required && (
              <>
                {" "}
                <span className="text-sm text-gray-400">(optional)</span>
              </>
            )}
          </label>
          <NotesInput
            className="mb-3"
            value={notes_2}
            required={notes_2Required}
            onChange={(e) => setNotes_2(e.target.value)}
          />
        </>
      )}
      {meta.hasNotes3 && (
        <>
          <label className="font-bold text-lg mb-1" htmlFor="item-quantity">
            {notes_3Label}
            {!notes_3Required && (
              <>
                {" "}
                <span className="text-sm text-gray-400">(optional)</span>
              </>
            )}
          </label>
          <NotesInput
            className="mb-3"
            multi
            value={notes_3}
            required={notes_3Required === "true"}
            onChange={(e) => setNotes_3(e.target.value)}
          />
        </>
      )}
      {!meta.isUniqueItem ? (
        <>
          <label className="font-bold text-lg mb-1" htmlFor="item-quantity">
            Quantity
          </label>
          <QuantityInput
            id="item-quantity"
            className="mb-3"
            aria-label="Quantity"
            onIncrement={() => setQuantity((q) => Math.min(q + 1, maxQty))}
            onDecrement={() => setQuantity((q) => Math.max(minQty, q - 1))}
            onChange={(e) => setQuantity(e.currentTarget.value)}
            onBlur={(event) => {
              let qty = event.currentTarget.value;
              if (qty < minQty) {
                setQuantity(minQty);
              } else if (qty > maxQty) {
                setQuantity(maxQty);
              }
            }}
            disabled={!variant}
            quantity={quantity}
            min={minQty}
            max={maxQty}
          />{" "}
        </>
      ) : (
        <UniqueItem className="mb-5" />
      )}
      <AddToCart
        className="self-stretch"
        disabled={validateCustomAttributes()}
        product={product}
        variantId={variant?.storefrontId}
        quantity={!meta.isUniqueItem ? quantity : 1}
        customAttributes={getCustomAttributes()}
        minQty={minQty}
        maxQty={maxQty}
        initStock={variant?.availableForSale ? IN_STOCK : OUT_OF_STOCK}
      />
    </div>
  );
};

export default ProductForm;

export const query = graphql`
  fragment ProductForm on ShopifyProduct {
    storefrontId
    priceRangeV2 {
      maxVariantPrice {
        amount
        currencyCode
      }
      minVariantPrice {
        amount
        currencyCode
      }
    }
    hasOnlyDefaultVariant
    variants {
      availableForSale
      storefrontId
      title
      price
      selectedOptions {
        name
        value
      }
      media {
        shopifyId
      }
      metafields {
        key
        value
      }
    }
    options {
      name
      values
      shopifyId
    }
    metafields {
      key
      value
    }
  }
`;

import React, { useState } from "react";
import { Link, graphql } from "gatsby";

import { toKabobCase } from "../../../utils/utils";
import { useMetafields } from "../../../hooks/useMetafields";

import Layout from "../../../components/layout";
import PageInfo from "../../../components/pageInfo";
import Breadcrumbs from "../../../components/breadcrumbs";
import ProductImages from "../../../components/productImages";
import ProductForm from "../../../components/productForm";
import ProductListing from "../../../components/productListing";

const ProductType = ({ data: { product, suggestedProducts } }) => {
  const {
    title,
    description,
    descriptionHtml,
    metafields,
    productType,
    productTypeSlug,
    tags,
  } = product;

  let titleParts = [title, productType, "Products"];

  const [variantImageId, setVariantImageId] = useState(null);
  const meta = useMetafields(metafields);

  const dimensionUnits = {
    MILLIMETERS: "mm",
    CENTIMETERS: "cm",
    METERS: "m",
    INCHES: '"',
    FEET: "'",
    YARDS: "yd",
  };
  const formatDimensions = (dims) =>
    dims.map((dim) => `${dim.value}${dimensionUnits[dim.unit]}`).join(` • `);

  return (
    <Layout>
      <PageInfo
        title={titleParts.join(" - ")}
        pageClass={titleParts.map((part) => toKabobCase(part)).join("--")}
        description={description}
      />

      <Breadcrumbs
        className="mb-10"
        crumbs={[
          { to: "/products/", label: "Products" },
          { to: productTypeSlug, label: productType },
          title,
        ]}
      />

      <div className="flex flex-col lg:flex-row mb-20">
        <div className="image-sticky lg:self-start lg:top-8 w-full lg:w-8/12 lg:mr-6 mb-6 lg:mb-0">
          <ProductImages product={product} variantImageId={variantImageId} />
        </div>
        <div className="w-full lg:w-4/12">
          <h1 className="text-4xl xl:text-5xl font-new-tegomin">{title}</h1>
          <div className="flex gap-2 flex-wrap mb-6 text-sm">
            <Link
              to={productTypeSlug}
              className="bg-gray-200 rounded px-2 py-1"
            >
              {productType}
            </Link>
            {tags.map((tag, index) => (
              <span key={index} className="rounded py-1">
                {tag}
              </span>
            ))}
          </div>
          <div
            className="mb-4"
            dangerouslySetInnerHTML={{ __html: descriptionHtml }}
          />
          <div className="mb-4">
            {meta.hasTowelMaterial && (
              <div className="text-lg">
                <span className="font-bold">Towel:</span>{" "}
                {meta.getTowelMaterial()}
              </div>
            )}
            {meta.hasQuiltSize && (
              <div className="text-lg">
                <span className="font-bold">Size:</span> {meta.getQuiltSize()}
              </div>
            )}
            {meta.hasQuiltDimensions && (
              <div className="text-lg">
                <span className="font-bold">Dimensions:</span>{" "}
                {formatDimensions(meta.getQuiltDimensions() || [])}
              </div>
            )}
          </div>
          <ProductForm
            product={product}
            onChangeVariantImage={setVariantImageId}
          />
        </div>
      </div>

      {suggestedProducts.nodes.length > 0 && (
        <section>
          <h2 className="text-4xl font-new-tegomin">Similar Products</h2>
          <hr className="mb-4" />
          <ProductListing products={suggestedProducts.nodes} />
        </section>
      )}
    </Layout>
  );
};

export default ProductType;

export const query = graphql`
  query ($id: String!, $productType: String!) {
    product: shopifyProduct(id: { eq: $id }) {
      title
      description
      descriptionHtml
      productType
      productTypeSlug: gatsbyPath(
        filePath: "/products/{ShopifyProduct.productType}"
      )
      tags
      metafields {
        key
        value
      }
      ...ProductImages
      ...ProductForm
    }
    suggestedProducts: allShopifyProduct(
      limit: 4
      filter: { productType: { eq: $productType }, id: { ne: $id } }
    ) {
      nodes {
        ...ProductCard
      }
    }
  }
`;
